import React from "react";

const loadMessage = (props) => {
  return (
    <>
      {props.isLoading ? (
        <>
          <div className="loadingBG"></div>
          <div className="loadingBG2">
            <div className="loading">
              <img
                src="https://cidev-media.s3.amazonaws.com/loader.gif" // Usando la URL directa
                alt="loader"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default loadMessage;
